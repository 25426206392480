
/* Cascading modal register / login  */
.form-cascading .modal-footer{
  border-top: none;
  flex-direction: column;
}

.form-cascading #options {
  margin-right: auto;
}

.form-cascading #options p {
  margin-bottom: 0;
}

.form-cascading #options p span{
  cursor: pointer;
}

/* Elegant modal login */
.form-elegant .modal-header {
  border-bottom: none;
}

.form-elegant .modal-footer{
  border-top: none;
  flex-direction: column;
}

.form-elegant .font-small {
  font-size: 0.8rem;
}

/* Dark modal register */
.form-dark .modal-content{
  background-image: url('https://mdbootstrap.com/img/Photos/Others/pricing-table%20(7).jpg');
}

.form-dark .modal-header {
  border-bottom: none;
}

.form-dark .modal-header .close {
  color: #fff;
}

.form-dark .modal-footer{
  border-top: none;
  flex-direction: column;
}

.form-dark input[type=email]:focus:not([readonly]) {
  border-bottom: 1px solid #00C851;
  -webkit-box-shadow: 0 1px 0 0 #00C851;
  box-shadow: 0 1px 0 0 #00C851;
}

.form-dark input[type=email]:focus:not([readonly]) + label {
  color: #fff;
}

.form-dark .font-small {
  font-size: 0.8rem;
}
